<template>
    <div class="download-resume">
        <div class="title">
            <div class="sub-title">收到的简历</div>
        </div>
        <el-alert
            title="温馨提示： 请自觉保护求职者个人信息隐私"
            type="warning"
            show-icon>
        </el-alert>
        <el-card class="box-card" style="min-height:100%">
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-select v-model="search.status" placeholder="按反馈" size="small" @change="statusChange" @clear="statusClear">
                        <el-option value="" label="全部">全部</el-option>
                        <el-option value="0" label="未标记">未标记</el-option>
                        <el-option value="1" label="可面试">可面试</el-option>
                        <el-option value="2" label="不合适">不合适</el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-select v-model="search.workId" clearable placeholder="请选择职位" size="small" @change="workIdChange" @clear="workIdClear">
                        <el-option
                            v-for="item in jobList"
                            :key="item.id"
                            :label="item.workName"
                            :value="item.id">
                            </el-option>
                    </el-select>
                </el-col>
            </el-row>
            <div v-if="state">
                <!-- <List :isCollection="isCollection" v-if="list.length>0"></List> -->
                <div>
                    <div v-if="list.length>0" class="resume-list">
                        <div class="reesume-item" v-for="(item, index) in list" :key="`jobre${index}`">
                            <div class="item-title flex">
                                <div class="title-left">
                                    投递职位:{{item.workName}} (<span style="font-weight:normal;">
                                        <em v-if="item.workType==1" style="font-style:normal;">全职</em>
                                        <em v-if="item.workType==2" style="font-style:normal;"></em>
                                        </span><span style="font-weight:normal;"> —— 到岗时间：{{item.workTime}}</span>)
                                </div>
                                <div class="title-right">
                                    投递时间：{{item.createTime.split(' ')[0]}}
                                </div>
                            </div>
                            <div class="pos-dtl flex">
                                <div class="pos-left flex">
                                    <div>
                                        <div class="avata">
                                            <img :src="item.heardImg||require('../../../assets/img/company/base_logo.png')" alt="">
                                        </div>
                                         <div class="left-text">{{item.userName}}</div> 
                                    </div>
                                    <div class="base">
                                        <div>基本情况</div>
                                        <div>{{item.gender===1?'男':'女'}}·{{item.age}}岁·{{item.eductionName}}</div>
                                        <div>工作经验：{{item.workExperienceName}}</div>
                                        <div>期望薪资：{{item.expectSalaryName}}</div>
                                    </div>
                                </div>
                                <div class="pos-right">
                                    <div class="tel-phone">
                                        <i class="el-icon-mobile-phone"></i>
                                        <span class="number">{{item.phone}}</span>
                                        <span class="opra" @click="openInviteBox(item)" v-if="!item.isInterview">邀请面试</span>
                                    </div>
                                    <div class="btns">
                                        <el-button size="mini" @click="routeToResumeInfo(item.resumeId)">查看详情</el-button>
                                        <el-button size="mini"  @click="openFeedbackBox(item)">标记</el-button>
                                        <el-button size="mini" @click="openadd(item)">发送信息</el-button>
                                        <el-button type="primary" icon="el-icon-video-play" size="mini" @click="gotoVideo(item)">视频面试</el-button>
                                    </div>
                                    <div class="resume-status">简历状态:
                                        <span v-if="item.status===1">可面试</span>
                                        <span v-if="item.status===0">未标记</span>
                                        <!-- <span v-if="item.status===2">待定</span> -->
                                        <span v-if="item.status===2">不合适</span>
                                        <span v-if="item.status===3">电话沟通 - {{item.message}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="line"></div>
                        </div>
                         <app-pagination @handleSizeChange="handleSize" @handleCurrentChange="handleCurrent" :total="total"></app-pagination>
                    </div>
                    <div class="no" v-else>
                        <img src="../../../assets/img/company/loading.png" alt="" srcset="" class="loading-img">
                        <p>正在加载中...</p>
                </div>
                </div>
            </div>
            <div class="no-data flex" v-else>
                <img src="../../../assets/img/company/empty.png" alt="" srcset="">
                <p class="no-tips">亲爱的用户，目前还没有求职者投递相关岗位~~</p>
            </div>
        </el-card>
        <el-dialog
            title="邀请面试"
            :visible.sync="inviteVisible"
            width="550px">
            <div class="messageInfo">
                <el-form ref="inviteFormRef" :model="inviteForm" :rules="inviteRules" label-width="100px">
                    <el-form-item label="受邀人:">
                        <el-input v-model="inviteForm.userName" disabled size="small" style="width:350px"></el-input>
                    </el-form-item>
                    <el-form-item label="面试时间:" prop="beginTime">
                        <el-date-picker
                            v-model="inviteForm.beginTime"
                            size="small"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            default-time="12:00:00"
                            :picker-options="pickerOptions"
                            style="width:350px"
                            type="datetime"
                            placeholder="选择面试时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="面试职位:" prop="workId">
                        <el-select v-model="inviteForm.workId" placeholder="请选择邀约职位" size="small" style="width:350px" disabled>
                            <el-option
                                v-for="item in jobList"
                                :key="item.id"
                                :label="item.workName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="公司地址:" prop="address">
                        <el-input v-model="inviteForm.address" size="small" style="width:350px" placeholder="请输入公司地址"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人:" prop="contacts">
                        <el-input v-model="inviteForm.contacts" size="small" style="width:350px" placeholder="请输入公司联系人姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话:" prop="contactsPhone">
                        <el-input v-model="inviteForm.contactsPhone" size="small" style="width:350px" placeholder="请输入公司联系人号码"></el-input>
                    </el-form-item>
                </el-form>
                
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="inviteVisible = false">取 消</el-button>
                <el-button type="primary" @click="invited" :loading="clickStatus">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="标记简历"
            :before-close="handleFeedbackClose"
            :visible.sync="feedbackVisible"
            width="400px">
            <el-radio-group v-model="changeResumeStatus.status">
                <el-radio :label="1">可面试</el-radio>
                <el-radio :label="2">不合适</el-radio>
                <el-radio :label="3">电话沟通</el-radio>
            </el-radio-group>
            <div style="margin-top:10px;">
                <el-input
                type="textarea"
                v-if="changeResumeStatus.status==3"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入备注"
                v-model="changeResumeStatus.message">
                </el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="feedbackVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveStatus">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="发送信息"
            :visible.sync="addmessage"
            width="600px">
            <div>
                <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入内容"
                v-model="form.message">
                </el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addmessageclose">取 消</el-button>
                <el-button type="primary" @click="saveMessage">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import List from '../components/List'
import AppPagination from '../../../components/pagination/AppPagination'
import companyRequest from '../../../api/company'
// 验证手机号的规则
let checkMobile = (rule, value, cb) => {
    const regMobile = /^1[0-9]{10}$/

    if (regMobile.test(value)) {
    return cb()
    }
    cb(new Error('请输入合法的手机号'))
}
export default {
    components: {
        List,
        AppPagination
    },
    created(){
        let member=JSON.parse(localStorage.getItem("userInfo"))
        this.form.memberId=member.id
    },
    mounted() {
        this.getJobList()
        this.queryMyDeliveryResume()
    },
    data() {
        return {
            addmessage:false,
            form:{message:null,type:2,userId:null,memberId:null,pid:0},
            list: [],
            jobList: [],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
                }
            },
            inviteVisible: false,
            feedbackVisible: false,
            state: true,
            clickStatus: false,
            total: 0,
            search: {
                pageNum: 1,
                pageSize: 10,
                status: null,
                workId: null
            },
            changeResumeStatus: { // 改变简历状态
                id: null,
                status: null,
                message:null,
            },
            inviteForm: { //邀请面试表单
                userId: '', //用户id
                userName: '', //用户姓名
                workId: '', //职位id    
                resumeId: '', //简历id
                beginTime: '', //面试时间
                address: '', //面试地址
                contacts: '', //联系人
                contactsPhone: '', //联系电话
                type: 0 // 0线下 1线上
            },
            inviteRules: { //校验规则
                beginTime:[{ required: true, message: '请选择面试时间', trigger: 'change' },],
                workId:[{ required: true, message: '请选择邀约职位', trigger: 'change' },],
                address:[{ required: true, message: '请输入公司地址', trigger: 'blur' },],
                contacts:[{ required: true, message: '请输入公司联系人姓名', trigger: 'blur' },],
                contactsPhone:[{ required: true, message: '请输入公司联系人号码', trigger: 'blur' },  { validator: checkMobile, trigger: 'blur' }],
            },
            radio: '',
        }
    },
    methods: {
        saveMessage(){
            companyRequest.sendMessage(this.form).then(res=>{
                if(res.success){
                    this.$message.success("发送成功！")
                    this.form.message=""
                }
            })
            this.addmessage=false

        },
        openadd(row){
            this.form.userId=row.userId
            this.addmessage=true
        },
        addmessageclose(){
            this.addmessage=false
        },
        gotoVideo(row){
            this.$router.push({path:"/company/videohome",query:{id:row.userId,t:row.userName}})
        },
        handleSize(size) {
            this.search.pageSize = size
            this.search.pageNum = 1
            this.queryMyDeliveryResume() 
        },
        handleCurrent(num) {
            this.search.pageNum = num
            this.queryMyDeliveryResume() 
        },
        //反馈搜索
        statusChange() {
            this.search.pageSize = 10
            this.search.pageNum = 1
            this.queryMyDeliveryResume()
        },
        //清除反馈搜索
        statusClear() {
            this.search.pageSize = 10
            this.search.pageNum = 1
            this.queryMyDeliveryResume()
        },
        //职位搜索发生变化
        workIdChange() {
            // console.log('workId', this.search.workId)
            this.search.pageSize = 10
            this.search.pageNum = 1
            this.queryMyDeliveryResume()
        },
        //清空职位搜索
        workIdClear() {
            // console.log('workId', this.search.workId)
            this.search.pageSize = 10
            this.search.pageNum = 1
            this.queryMyDeliveryResume()
        },
        //收到的简历列表
        queryMyDeliveryResume() {
            this.state = true
           companyRequest.queryMyDeliveryResume(this.search).then((res) => {
                if (res.code === 200) {
                    this.list = res.data.list
                    this.total = res.data.total
                    // console.log('list', this.list)
                    if (this.list.length === 0) {
                        this.state = false
                    }
                }
           }).catch((err) => {
               this.state = false
           })
        },
        routeToResumeInfo(id) {
            this.$router.push({
                path: '/company/resumeInfo',
                query: {
                    id: id
                }
            })
        },
        invited() {
            this.$refs.inviteFormRef.validate((valid) => {
                if (valid) {
                    // console.log('inviteForm', this.inviteForm)
                    this.clickStatus = true
                    companyRequest.addInterview(this.inviteForm).then((res) => {
                        if (res.code === 200) {
                            this.$message.success('邀约面试成功~')
                            this.inviteVisible = false
                            this.queryMyDeliveryResume()
                            setTimeout(() => {
                                this.clickStatus = false
                            }, 500)
                        }
                    }).catch((err)=> {
                        this.$message.error(err)
                        this.inviteVisible = false
                        this.queryMyDeliveryResume()
                        setTimeout(() => {
                            this.clickStatus = false
                        }, 500)
                    })
                } else {
                    // console.log('error submit!!')
                    return false
                }
            })
        },
        //获取企业的职位列表
        getJobList() {
            companyRequest.getJobList().then((res) => {
                if (res.code === 200) {
                    this.jobList = res.data
                }
            })
        },
        openInviteBox(row) {
            this.inviteForm.userName = row.userName
            this.inviteForm.userId = row.userId
            this.inviteForm.resumeId = row.resumeId
            this.inviteForm.workId = row.workId

            this.inviteVisible = true
        },
        openFeedbackBox(row) {
            this.changeResumeStatus.id = row.id
            this.changeResumeStatus.status = row.status
            this.changeResumeStatus.message = row.message
            console.log('changeResumeStatus', this.changeResumeStatus)
            this.feedbackVisible = true
        },
        handleFeedbackClose() {
            this.changeResumeStatus.id = null
            this.changeResumeStatus.status = null
            this.changeResumeStatus.message = null
            this.feedbackVisible = false
        },
        //保存标记状态
        saveStatus() {
            if(this.changeResumeStatus.status!=3){
                this.changeResumeStatus.message=null
            }
            //console.log('changeResumeStatus', this.changeResumeStatus)
            companyRequest.changeResumeStatus(this.changeResumeStatus).then((res) => {
                if (res.code === 200) {
                    this.$message.success('简历反馈成功~')
                    this.changeResumeStatus.id = null
                    this.changeResumeStatus.status = null
                    this.changeResumeStatus.message = null
                    this.feedbackVisible = false
                    this.queryMyDeliveryResume()
                }
            }).catch((err) => {
                this.$message.error(err)
                this.changeResumeStatus.id = null
                this.changeResumeStatus.status = null
                this.changeResumeStatus.message = null
                this.feedbackVisible = false
                this.queryMyDeliveryResume()
            })
        }
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
    .download-resume{
        height: 100%;
        .el-alert{
            padding: 15px;
            font-size: 14px !important;
        }
        .el-card{
            margin-top: 20px;
        }
        .el-row{
            margin-bottom: 25px;
        }
        .no-data{
            margin: 150px 0 200px 0;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
        .resume-list{
          .reesume-item{
            .item-title{
                justify-content: space-between;
                height: 60px;
                align-items: center;
                padding: 0 20px;
                border-bottom: 1px solid #eee;
                .title-left{
                font-weight: 700;
                color: #6d747d;
                font-size: 14px;
                }
                .title-right{
                color: #585858;
                }
            }
            .pos-dtl{
                padding: 30px 20px;
                justify-content: space-between;
                .pos-left{
                    align-items: flex-start;
                    padding-right: 400px;
                    border-right: 1px solid #eee;
                    .avata{
                        width: 80px;
                        height: 80px;
                        margin-bottom: 10px;
                        overflow: hidden;
                        img{
                            width: 80px;
                            height: 80px;
                        }
                    }
                    .left-text{
                        width: 80px;
                        text-align: center;
                    }
                    .base{
                        margin-left: 20px;
                        font-size: 14px;
                        color: #666;
                        div{
                            margin-bottom: 10px;
                        }
                    }
                }
                .pos-right{
                    align-self: center;
                    // margin-right: 40px;
                    .tel-phone{
                        .el-icon-mobile-phone{
                            font-size: 18px;
                            cursor: default;
                            font-weight: bold;
                            color: #5d9cec;
                            margin-right: 10px;
                        }
                        .number{
                            font-size: 18px;
                        }
                        .opra{
                            display: inline-block;
                            position: relative;
                            top: -3px;
                            width: 90px;
                            height: 30px;
                            cursor: pointer;
                            line-height: 30px;
                            border-radius: 2px;
                            margin-left: 12px;
                            text-align: center;
                            color: #fff;
                            background: $main;
                            &:hover{
                                background: $hover;
                            }
                        }
                    }
                    .btns{
                        margin-top: 15px;
                    }
                }
            }
            .line{
                height: 15px;
                background: #f0f2f5;
            } 
          }  
        }
        .messageInfo{

        }
        .resume-status{
            max-width: 400px;
            margin-top: 10px;
            color: #bbb;
            font-size: 12px;
        }
    }
</style>