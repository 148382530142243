<template>
    <div class="menu-center">
        <div class="number-table">
            <div class="number-table-item">
                <div class="avata">
                    <img src="../../../assets/img/company/avata.png" alt=""  />                        
                </div>
                <div class="user-info">
                    <el-popover placement="right-top" trigger="hover" >
                        <div class="resume">
                            <div class="done flex">
                                简历完成度：{{percentage}} 
                                <div class="progress">
                                    <div class="progress-do" :style="{'width': percentage}"></div>
                                </div>
                            </div>
                            <div class="name">semball  (男 , 23岁)</div>
                            <div class="hope-job">
                                <p>期盼职位：护士/护理、礼仪/迎宾、订票员、行政专员/助理、销售助理</p>
                                <p>期望薪资：5000-8000元</p>
                                <p>最高学历：本科</p>
                            </div>
                            <div class="job-list">
                                <p>工作了<span class="tips">11年5个月</span>,做了<span class="tips">4</span>份工作</p>
                                <div class="conpany">
                                    <el-tag type="info" size="small">趣星传媒</el-tag>
                                    <el-tag type="info" size="small">趣星传媒</el-tag>
                                    <el-tag type="info" size="small">趣星传媒</el-tag>
                                    <el-tag type="info" size="small">趣星传媒</el-tag>
                                    <el-tag type="info" size="small">趣星传媒</el-tag>
                                    <el-tag type="info" size="small">趣星传媒</el-tag>
                                    <el-tag type="info" size="small">趣星传媒</el-tag>
                                    <el-tag type="info" size="small">趣星传媒</el-tag>
                                    <el-tag type="info" size="small">趣星传媒</el-tag>
                                </div>
                            </div>
                        </div>
                        <div class="user-info-wage" slot="reference">
                            <p class="name">semball 男 · 23岁 · 本科 · 江西上饶 </p>
                                <div class="tags-warpper">
                                <div class="tags">
                                    <el-tag size="small">标签一</el-tag>
                                    <el-tag type="success" size="small">标签二</el-tag>
                                    <el-tag type="info" size="small">标签三</el-tag>
                                    <el-tag type="warning" size="small">标签四</el-tag>
                                    <el-tag type="danger" size="small">标签五</el-tag>
                                </div>
                                <div class="no-tag"></div>
                            </div>
                        </div>
                    </el-popover> 
                </div>
                <!-- <div class="user-info">
                    <p class="name">semball 男 · 23岁 · 本科 · 江西上饶 </p>
                    <div class="tags-warpper">
                        <div class="tags">
                            <el-tag size="small">标签一</el-tag>
                            <el-tag type="success" size="small">标签二</el-tag>
                            <el-tag type="info" size="small">标签三</el-tag>
                            <el-tag type="warning" size="small">标签四</el-tag>
                            <el-tag type="danger" size="small">标签五</el-tag>
                        </div>
                        <div class="no-tag"></div>
                    </div>
                </div> -->
                <div class="position">
                    <div class="position-name">
                        导游 | 
                        <span class="position-name-desc">1-3年工作时间</span>
                    </div>
                    <div class="position-time">
                        求职意向: 
                        <span class="position-time-desc">财务/审计/税务</span>
                    </div>
                </div>
                <div class="wage">
                    <div class="wage-info">期望薪资</div>
                    <div class="wage-number">8000元</div>
                </div>
                <div class="download">
                    <!-- <el-button class="success">查看简历</el-button>
                    <el-button class="active">下载简历</el-button> -->
                    <div class="success button border">查看简历</div>
                    <div class="active button border">下载简历</div>
                    <div class="collection button border" v-if="isCollection">收藏简历</div>
                    <div class="collection button border" v-if="isCollection">反馈简历</div>
                </div>
                <!-- <div class="download">
                    <el-button class="active">下载简历</el-button>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        percentage: {
            type: String,
            default: '40%'
        },
        isCollection: { //是否显示收藏按钮
            type: Boolean,
            default: true
        }
    },
    data() {
        return {

        }
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
    .number-table{
        border: 1px solid #eee;
        margin-bottom: 20px;
      .number-table-item{
            padding: 20px 20px;
            border-bottom: 1px solid #eee;
            display: flex;
            align-items: center;
            &:hover{
                background: #f6f6f6;
            }
        .avata{
            width: 10%;
            img{
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
        }
        .user-info{
            width: 30%;
            display: flex;
            flex-direction: column;
            min-height: 60px;
            justify-content: space-between;
            .name{
                color: #555;
                font-size: 12px;
            }
            .tags-warpper{
                margin-top: 30px;
                .tags{
                    .el-tag{
                        margin-right: 10px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .button{
            padding: 8px 12px;
        }
        .user-info-wage{
            display: flex;
            flex-direction: column;
            min-height: 60px;
            justify-content: space-between;
        }
        .resume{
            max-width: 500px;
            .done{
                font-size: 12px;
                color: #828282;
               justify-content: flex-end;
               .progress{
                   margin-left: 10px;
                   margin-top: 2px;
                   width: 100px;
                   height: 10px;
                   background: #e1e1e1;
                   .progress-do{
                       height: 100%;
                       background: $main;
                   }
               } 
            }
            .name{
                font-size: 16px;
                font-weight: 700;
                line-height: 46px;
                color: #282828;
                border-bottom: 1px solid #eee;
            }
            .hope-job{
                padding: 5px 0;
                border-bottom: 1px solid #eee;
                p{
                    font-size: 12px;
                    line-height: 20px;
                    color: #747474;
                }
            }
            .job-list{
                padding: 5px 0;
                border-bottom: 1px solid #eee;
              .tips{
                  color: #ff0522;
                  font-weight: 700;
              }
              .conpany{
                  padding-top: 10px;
                  .el-tag{
                      margin: 5px 10px 5px 0;
                  }
              }  
            }
        }
        .position{
            width: 30%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            min-height: 60px;
            .position-name{
                font-size: 14px;
                color: #111;
                .position-name-desc{
                    font-size: 12px;
                    color: #333;
                }
            }
            .position-time{
                font-size: 12px;
                color: #555;
                .position-time-desc{
                    color: $main;
                }
            }
        }
        .wage{
            width: 10%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            .wage-info{
                font-size: 12px;
                color: #555;
            }
            .wage-number{
                margin-top: 7px;
                letter-spacing: 2px;
                font-weight: 550;
            }
        }
        .download{
            width: 20%;
            box-sizing: border-box;
            display: flex;
            padding-left: 90px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            div{
                cursor: pointer;
            }
            
            // .el-button{
            //     width: 40%;
            //     &:hover{
            //         background: $hover;
            //     }
            // }
        }  
      }  
    }
</style>